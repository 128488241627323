import React, { useEffect, useState, useRef, useCallback } from 'react';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { Cookies } from 'react-cookie';
import { pollUserInfo } from '../actions/authActions';

function useSession() {
  const [showSessionExpire, setShowSessionExpire] = useState(false);
  const [countDownSeconds, setCountDownSeconds] = useState(60);
  const dispatch = useDispatch();
  const intervalRef = useRef(null);

  // useEffect(() => {
  //   setTimeout(() => {
  //     sessionPooling();
  //   }, 2000);
  // }, []);

  useEffect(() => {
    // Call sessionPooling after 2 seconds
    const timeoutId = setTimeout(() => {
      sessionPooling();
    }, 2000);

    // Cleanup function to clear both interval and timeout on unmount
    return () => {
      clearTimeout(timeoutId);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [sessionPooling]);

  const handleSessionExpireModal = tokenExpiration => {
    const expirationTime = moment(tokenExpiration);
    const currentTime = moment();

    // Calculate the difference in seconds
    const differenceInSeconds = expirationTime.diff(currentTime, 'seconds');

    // Check if the difference is greater than 0 and less than 60 seconds
    if (differenceInSeconds > 0 && differenceInSeconds <= 60) {
      setShowSessionExpire(true);
      setCountDownSeconds(differenceInSeconds);
      // Add your logic here
    }

    // Check if the difference is 0 or less than 0

    // if (differenceInSeconds > 60) {
    //   setShowSessionExpire(false);
    //   setCountDownSeconds(60);
    // }

    // Check if the difference is within 1 minute (adjust as needed)
    // return differenceInMinutes <= 1 && differenceInMinutes >= 0;
  };

  const checkSession = () => {
    const cookies = new Cookies();
    const tokenExpiresAt = cookies.get('expires_at');
    const expirationTime = moment(tokenExpiresAt);
    const currentTime = moment();
    const differenceInSeconds = expirationTime.diff(currentTime, 'seconds');

    if ([5, 10, 20, 30, 65, 80, 120, 160, 200, 240].includes(differenceInSeconds)) {
      dispatch(
        pollUserInfo((status, message) => {
          if (status) {
            handleSessionExpireModal(tokenExpiresAt);
          }
        }),
      );
    } else {
      handleSessionExpireModal(tokenExpiresAt);
    }
  };

  // const sessionPooling = () => {
  //   setInterval(() => {
  //     checkSession();
  //   }, 1000);
  // };

  const sessionPooling = useCallback(() => {
    // Start the session pooling with an interval
    intervalRef.current = setInterval(() => {
      checkSession();
    }, 1000);
  }, []);

  const handleCloseSessionModal = () => {
    setShowSessionExpire(false);
  };

  return { showSessionExpire, handleCloseSessionModal, countDownSeconds };
}

export default useSession;
